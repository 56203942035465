import { Link } from 'react-router-dom';

function Banner(props) {
    return (
        <>
        <div className="banner">
            <div className='container'>
                <h5 className="heading text-uppercase">{props.name}</h5>
                <div className='breadcrumbs'>
                    <Link to={props.parentSlug}>{props.parent} / </Link> {props.subParent ? <Link to={props.subParentSlug}>{props.subParent} / </Link> : ''} <strong className='text-uppercase'>{props.name}</strong>
                </div>
            </div>
        </div>
        </>
        );
}

export default Banner;