import React, {useEffect, useState} from 'react';
import axios from "axios";
import appConstant from "./constant/constant.json";
import LoadingSpinner from './LoadingSpinner.js';

function Faqs(props) {
    const {page} = props;
    const [isLoading, setIsLoading] = useState(true);
    const [faqsItem, setFaqsItem] = useState([]);
    useEffect(() => {
        faqs(page);
    }, [page]);
    const faqs = (faqId) => {
      const params = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
     const url = (page !== '0') ? `${appConstant.API_URL}/c/faqs/${faqId}` : `${appConstant.API_URL}/c/faqs/1`;
      axios.get(url, params)
          .then(res => {
            setIsLoading(false);
            if(res?.data?.status === 200){
              setFaqsItem(res?.data?.data);
            }else{
                setFaqsItem([]);
            }
          })
          .catch(error => {
            setIsLoading(false);
          });
    }

    return (
        <>
        {isLoading ? (
          <LoadingSpinner />
                ) : (
                     <>
                <div id="accordion" className="text-start">
                {faqsItem?.length > 0  && faqsItem?.map((result, i) =>
                    <div className="card mb-2 bg-transparent border-0" key={'faqs' + i}>
                        <a className="card-header collapsed" data-bs-toggle="collapse" href={"#collapse" + i}>
                        <span> {result?.title}</span> <i className="fa-solid fa-angle-up"></i>
                        </a>
                        <div id={"collapse" + i} className="collapse" data-bs-parent="#accordion">
                            <div className="card-body">
                            {result?.description}
                            </div>
                        </div>
                    </div>
                    )}
                </div>
                </>
                )}
        </>
        );
}

export default Faqs;