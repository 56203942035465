import React from 'react';
import { Link } from 'react-router-dom';

function Sidebar(props) {
    return (
    <>
        <div className='card sidebar'>
            <div className='card-body'>
                <Link className={"list-item " + (props.id === "1" ? 'active' : '')} to="/my-profile">My Profile</Link>
                <div className="dropdown">
                    <button type="button" className={"list-item dropdown-toggle " + (props.id === "2" ? 'active' : '')} data-bs-toggle="dropdown">
                    My Orders
                    </button>
                    <ul className="dropdown-menu orders-menu">
                        <li><a href={"/my-orders/products"} className="dropdown-item">Products</a></li>
                        <li><a href={"/my-orders/services"} className="dropdown-item">Services</a></li>
                    </ul>
                </div>
                <Link className={"list-item " + (props.id === "3" ? 'active' : '')} to="/shipping-address">Shipping Address</Link>
                <Link className="list-item " to="/logout">Logout</Link>
            </div>
        </div>
        {/* <div className='sidebar-mobile'>
            <div className='d-flex'>
                <select className='mb-0 me-3' name="pages">
                    <option value='my-profile'>My Profile</option>
                    <option value='product-order'>Product Orders</option>
                    <option value='service-order'>Service Orders</option>
                    <option value='shipping-address'>Shipping Address</option>
                </select>
                <button className='btn btn-purple'>Go</button>
            </div>
            
        </div> */}
    </>
  )
};
export default Sidebar;