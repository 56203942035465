import Header from "./shared/Header";
import Footer from "./shared/Footer";
import Banner from "./shared/Banner";

function PrivacyPolicy() {
    return (
        <>
            <Header/>
            <Banner name='Privacy Policy' parentSlug='/' parent='Home'/>
            
            <div className="container py-5">
                <h4 className="h6 mb-4">Welcome to the Privacy Policy of Sage Controllers. This Privacy Policy describes how we collect, use, and disclose information when you visit sagecontrollers.com or make purchases from our website. We are committed to protecting your privacy and ensuring the security of your personal information. By using our website, you agree to the terms and practices outlined in this Privacy Policy. Please read this policy carefully.</h4>
                <h4 className="h3 fw-bold mb-2">Information We Collect</h4>
                <ul>
                    <li><h4 className="h6 mb-4">Personal Information: When you create an account or make a purchase, we may collect personal information such as your name, email address, shipping address, and payment details. We only collect the information necessary to fulfill your order and provide customer support.</h4></li>
                    <li><h4 className="h6 mb-4">Device and Usage Information: We may automatically collect certain information about your device, browser, and usage patterns when you visit our website. This includes your IP address, browser type, operating system, referring URLs, pages visited, and the dates and times of your interactions with our website.</h4></li>
                    <li><h4 className="h6 mb-4">Cookies and Similar Technologies: We use cookies and similar tracking technologies to enhance your experience on sagecontrollers.com. Cookies are small text files that are stored on your device and help us recognize you, remember your preferences, and provide a personalized browsing experience.</h4></li>
                </ul>

                <h4 className="h3 fw-bold mb-2">Use of Information</h4>
                <ul>
                    <li><h4 className="h6 mb-4"><strong>Order Processing:</strong> We use your personal information to process and fulfill your orders, communicate with you regarding your purchases, and provide customer support.</h4></li>
                    <li><h4 className="h6 mb-4"><strong>Personalization:</strong> We may use the information collected to personalize your experience on our website, recommend products, and display relevant content or advertisements based on your preferences.</h4></li>
                    <li><h4 className="h6 mb-4"><strong>Marketing Communications:</strong> With your consent, we may send you promotional emails or newsletters about our products, discounts, and special offers. You can opt-out of receiving marketing communications at any time by following the unsubscribe instructions provided in the emails.</h4></li>
                    <li><h4 className="h6 mb-4"><strong>Analytics and Improvements:</strong> We analyze the information collected to understand user behavior, improve our website, and enhance our product offerings. This includes measuring the effectiveness of our marketing campaigns, identifying trends, and troubleshooting issues.</h4></li>
                    <li><h4 className="h6 mb-4"><strong>Legal Compliance:</strong> We may disclose your information if required by law or in response to a valid legal request, such as a court order or government inquiry.</h4></li>
                </ul>

                <h4 className="h3 fw-bold mb-2">Data Security</h4>
                <ul>
                    <li><h4 className="h6 mb-4"><strong>Security Measures:</strong> We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission or storage over the internet is 100% secure, and we cannot guarantee absolute security.</h4></li>
                    <li><h4 className="h6 mb-4"><strong>Payment Processing:</strong> We utilize reputable third-party payment processors to handle all transactions made on sagecontrollers.com. We DO NOT store or have access to your payment card details.</h4></li>
                </ul>

                <h4 className="h3 fw-bold mb-2">Third-Party Disclosure</h4>
                <ul>
                    <li><h4 className="h6 mb-4"><strong>Service Providers:</strong> We may share your information with trusted third-party service providers who assist us in operating our website, processing payments, delivering products, or conducting business activities on our behalf. These service providers are contractually obligated to maintain the confidentiality and security of your information.</h4></li>
                    <li><h4 className="h6 mb-4"><strong>Legal Requirements:</strong> We may disclose your information in good faith if required to comply with applicable laws, regulations, legal processes, or enforceable governmental requests.</h4></li>
                    <li><h4 className="h6 mb-4"><strong>Third-Party Links:</strong> Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third parties. We encourage you to review the privacy policies of these websites before providing any personal information.</h4></li>
                </ul>

                <h4 className="h3 fw-bold mb-2">Children and Privacy</h4>
                <h4 className="h6 mb-4">sagecontrollers.com understands how important it is to protect children's privacy. We will never request personally identifiable information from anyone whom we know to be under the age of 13 without verifiable parental consent. By using the Site, you represent and warrant that you are at least 13 years old. A parent or legal guardian who needs to access, change, or delete personal information regarding their child should contact admin@sagecontrollers.com.</h4>

                <h4 className="h3 fw-bold mb-2">Your Choices and Rights</h4>
                <h4 className="h6 mb-4"><strong>Access and Correction:</strong> You have the right to access, update, or correct your personal information held by us. You can do so by logging into your account or contacting our customer support team.</h4>
                <h4 className="h6 mb-4"><strong>Opt-Out:</strong> You have the option to opt-out of receiving marketing communications from us by following the instructions provided in the emails or contacting our customer support team.</h4>
                <h4 className="h6 mb-4"><strong>Data Removal: </strong> If you would like us to remove your personal information from our records, please submit a request at admin@sagecontrollers.com. </h4>
                <h4 className="h6 mb-4">At Sage Controllers, we are committed to continuously improving our practices and ensuring transparency with our users. Therefore, if necessary, we may update this Privacy Policy to enhance clarity and honesty in our communication with you. We reserve the right to update or modify the Privacy Policy at our discretion. We encourage you to review this policy regularly to stay informed about any updates.</h4>
                <h4 className="h6 mb-4">At Sage Controllers, we strive to uphold ethical practices and continuously improve our services. As part of this commitment, we may update our Privacy Policy to enhance transparency and provide you with clearer and more comprehensive information. We reserve the right to update or modify the Privacy Policy at our discretion. We encourage you to review this policy regularly to stay informed about any updates.</h4>

                <h4 className="h3 fw-bold mb-2">Contact Us</h4>
                <h4 className="h6 mb-4">If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at admin@sagecontrollers.com. We will respond to your inquiries as soon as reasonably possible.</h4>
                <h4 className="h6 mb-4">Thank you for choosing Sage Controllers.</h4>
            </div>
            
            <Footer/>
        </>
    );
  }
  
  export default PrivacyPolicy;
  