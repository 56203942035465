import React, {useEffect, useState} from 'react';
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import Banner from "./shared/Banner";
import Sidebar from "./shared/Sidebar";
import axios from 'axios';
import appConstant from './shared/constant/constant.json';
import LoadingSpinner from "./shared/LoadingSpinner";

function ShippingAddress() {
  useEffect(() => {
    getShippingDetails();
  }, []);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [shippingData, setShippingData] = useState(false);
    const [inputValues, setInputValue] = useState({
        fname: "",
        lname: "",
        number: "",
        email: "",
        address: "",
        country: "India",
        state: "",
        city: "",
        zip: ""
    });
    const [validation, setValidation] = useState({
        fname: "",
        lname: "",
        number: "",
        email: "",
        address: "",
        // country: "",
        state: "",
        city: "",
        zip: ""
    });
    function handleChange(e) {
        const { name, value } = e.target;
        setInputValue({ ...inputValues, [name]: value });
    }
    const checkValidation = () => {
        let errors = JSON.parse(JSON.stringify(validation));
    
        //first validation
        const alphabetCond = /^[a-zA-Z ]*$/;
        if (!inputValues.fname) {
        errors.fname = "First name is required";
        } else if (!inputValues.fname.match(alphabetCond)) {
        errors.fname = "Please enter valid first name";
        } else {
        errors.fname = "";
        }
        //last validation
        if (!inputValues.lname) {
        errors.lname = "Last name is required";
        } else if (!inputValues.lname.match(alphabetCond)) {
        errors.lname = "Please enter valid last name";
        } else {
        errors.lname = "";
        }
        //number validation
        // const numberCond = /^[0-9\b]+$/;
        if (!inputValues.number) {
        errors.number = "Number is required";
        }
        // else if (!inputValues.number.match(numberCond)) {
        // errors.number = "Please enter valid number";
        // }
        else {
        errors.number = "";
        }
        // email validation
        const emailCond = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!inputValues.email) {
          errors.email = "Email is required";
        } else if (!inputValues.email.match(emailCond)) {
          errors.email = "Please enter valid email address";
        } else {
          errors.email = "";
        }
        //address validation
        if (!inputValues.address) {
        errors.address = "Address is required";
        }else {
        errors.address = "";
        }
        //country validation
        // if (!inputValues.country) {
        //   errors.country = "Country is required";
        //   }else {
        //   errors.country = "";
        // }
        //state validation
        if (!inputValues.state) {
          errors.state = "State is required";
          }else {
          errors.state = "";
        }
        //city validation
        if (!inputValues.city) {
          errors.city = "City is required";
          }else {
          errors.city = "";
        }
        //zip validation
        if (!inputValues.zip) {
          errors.zip = "Zip is required";
          }else {
          errors.zip = "";
        }
  
        setValidation(errors);
        if(errors.fname === '' && errors.lname === '' && errors.number === '' && errors.email === '' && errors.address === '' && errors.state === ''&& errors.city === '' && errors.zip === ''){
          return true;
        }else{
          return false;
        }
    };
    const addShippingAddressApi = () => {
      setIsLoadingButton(true);
      const shippingDetailsData = new FormData();
      shippingDetailsData.append('token', localStorage.getItem('token') );
      shippingDetailsData.append('first_name', inputValues.fname );
      shippingDetailsData.append('last_name', inputValues.lname );
      shippingDetailsData.append('email', inputValues.email );
      shippingDetailsData.append('address', inputValues.address );
      shippingDetailsData.append('country', inputValues.country );
      shippingDetailsData.append('mobile_number', inputValues.number );
      shippingDetailsData.append('state', inputValues.state );
      shippingDetailsData.append('city', inputValues.city );
      shippingDetailsData.append('postal_code', inputValues.zip );
      const params = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      };
      axios.post(`${appConstant.API_URL}/user/update_address/shipping`, shippingDetailsData, params)
          .then(res => {
            setIsLoadingButton(false);
            if (res?.data?.status === 200) {
              console.log(res?.data?.message);
              const addData = {
                fname: "",
                lname: "",
                number: "",
                email: "",
                address: "",
                country: "",
                state: "",
                city: "",
                zip: ""
              }
              setInputValue(addData);
              getShippingDetails();
            }else {
  
            }
          })
          .catch(error => {
            setIsLoadingButton(false);
          });
  }
  
    const addAddress = (e) => {
      e.preventDefault();
      const isSuccess = checkValidation();
      if(isSuccess) {
          setIsLoadingButton(true);
          addShippingAddressApi();
      }
  }

  const [addressValues, setAddressValues] = useState({
      fname: "",
      lname: "",
      number: "",
      email: "",
      address: "",
      country: "India",
      state: "",
      city: "",
      zip: ""
  });
  const [addressValidation, setAddressValidation] = useState({
      fname: "",
      lname: "",
      number: "",
      email: "",
      address: "",
      // country: "",
      state: "",
      city: "",
      zip: ""
  });
  function handleAddressChange(e) {
      const { name, value } = e.target;
      setAddressValues({ ...addressValues, [name]: value });
  }
  const checkAddressValidation = () => {
      let errors = JSON.parse(JSON.stringify(addressValidation));
  
      //first validation
      const alphabetCond = /^[a-zA-Z ]*$/;
      if (!addressValues.fname) {
      errors.fname = "First name is required";
      } else if (!addressValues.fname.match(alphabetCond)) {
      errors.fname = "Please enter valid first name";
      } else {
      errors.fname = "";
      }
      //last validation
      if (!addressValues.lname) {
      errors.lname = "Last name is required";
      } else if (!addressValues.lname.match(alphabetCond)) {
      errors.lname = "Please enter valid last name";
      } else {
      errors.lname = "";
      }
      //number validation
      // const numberCond = /^[0-9\b]+$/;
      if (!addressValues.number) {
      errors.number = "Number is required";
      }
      // else if (!addressValues.number.match(numberCond)) {
      // errors.number = "Please enter valid number";
      // }
      else {
      errors.number = "";
      }
      // email validation
      const emailCond = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!addressValues.email) {
        errors.email = "Email is required";
      } else if (!addressValues.email.match(emailCond)) {
        errors.email = "Please enter valid email address";
      } else {
        errors.email = "";
      }
      //address validation
      if (!addressValues.address) {
      errors.address = "Address is required";
      }else {
      errors.address = "";
      }
      //country validation
      // if (!addressValues.country) {
      //   errors.country = "Country is required";
      //   }else {
      //   errors.country = "";
      // }
      //state validation
      if (!addressValues.state) {
        errors.state = "State is required";
        }else {
        errors.state = "";
      }
      //city validation
      if (!addressValues.city) {
        errors.city = "City is required";
        }else {
        errors.city = "";
      }
      //zip validation
      if (!addressValues.zip) {
        errors.zip = "Zip is required";
        }else {
        errors.zip = "";
      }

      setAddressValidation(errors);
      if(errors.fname === '' && errors.lname === '' && errors.number === '' && errors.email === '' && errors.address === '' && errors.state === ''&& errors.city === '' && errors.zip === ''){
        return true;
      }else{
        return false;
      }
  };
  const updateShippingAddress = () => {
    const isSuccess = checkAddressValidation();
    if(isSuccess) {
      setIsLoadingButton(true);
      const shippingDetailsData = new FormData();
      shippingDetailsData.append('token', localStorage.getItem('token') );
      shippingDetailsData.append('first_name', addressValues.fname );
      shippingDetailsData.append('last_name', addressValues.lname );
      shippingDetailsData.append('email', addressValues.email );
      shippingDetailsData.append('address', addressValues.address );
      shippingDetailsData.append('country', addressValues.country );
      shippingDetailsData.append('mobile_number', addressValues.number );
      shippingDetailsData.append('state', addressValues.state );
      shippingDetailsData.append('city', addressValues.city );
      shippingDetailsData.append('postal_code', addressValues.zip );
      const params = {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*"
      };
      const id = addressValues.shippingId;
      axios.post(`${appConstant.API_URL}/user/update_address/shipping/${id}`, shippingDetailsData, params)
      .then(res => {
          setIsLoadingButton(false);
          if (res?.data?.status === 200) {
              console.log(res?.data?.message);
          }else {

          }
      })
      .catch(error => {
          setIsLoadingButton(false);
      });
    }
  }
  const getShippingDetails = () => {
    setIsLoading(true);
    const shippingDetailsData = new FormData();
    shippingDetailsData.append('token', localStorage.getItem('token') );
    const params = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
    };
    axios.post(`${appConstant.API_URL}/user/address/shipping`, shippingDetailsData, params)
          .then(res => {
              setIsLoading(false);
              if (res?.data?.status === 200) {
                setShippingData(res?.data?.data);
              }else {
                
              }
          })
          .catch(error => {
              setIsLoading(false);
          });
  }
  const manage_address = (type, id) => {
    const shippingDetailsData = new FormData();
    shippingDetailsData.append('token', localStorage.getItem('token') );
    shippingDetailsData.append('id', id);
    const params = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    };
    axios.post(`${appConstant.API_URL}/user/manage_address/${type}/${id}`, shippingDetailsData, params)
        .then(res => {
          if (res?.data?.status === 200) {
            getShippingDetails();
          }else {

          }
        })
        .catch(error => {
        });
  }
  const getShipping = (id) => {
    setIsLoading(true);
    const shippingDetailsData = new FormData();
    shippingDetailsData.append('token', localStorage.getItem('token') );
    const params = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    };
    axios.post(`${appConstant.API_URL}/user/address/shipping/${id}`, shippingDetailsData, params)
        .then(res => {
          setIsLoading(false);
          if (res?.data?.status === 200) {
            const newData = {
              shippingId: res?.data?.data?.id,
              fname:  res?.data?.data?.first_name,
              lname: res?.data?.data?.last_name,
              number: res?.data?.data?.mobile_number,
              email: res?.data?.data?.email,
              address: res?.data?.data?.address,
              country: res?.data?.data?.country,
              state: res?.data?.data?.state,
              city: res?.data?.data?.city,
              zip: res?.data?.data?.postal_code,
              company: res?.data?.data?.company
            }
            setAddressValues(newData);
          }else {

          }
        })
        .catch(error => {
          setIsLoading(false);
        });
  }

  return (
    <>
      <Header />
      <Banner name="Shipping Address" parentSlug="/" parent="Home" />
      <div className="faqsSection py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-md-0 mb-0">
              <Sidebar id="3" />
            </div>
            <div className="col-md-8 mb-md-0 mb-4">
            <div className='card'>
                    <div className='card-header'>
                        <h4 className="h5 mb-0">Shipping Address</h4>
                    </div>
                    <div className='card-body'>
                      <div className='row'>
                            <div className='col-sm-6'>
                            <label className='cart-product-version mb-1'>First Name</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter First Name' name="fname" value={inputValues.fname} onChange={(e) => handleChange(e)}/>
                            {validation.fname && <p className='formErrors'>{validation.fname}</p>}
                            </div>
                            <div className='col-sm-6'>
                            <label className='cart-product-version mb-1'>Last Name</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter Last Name' name="lname" value={inputValues.lname} onChange={(e) => handleChange(e)}/>
                            {validation.lname && <p className='formErrors'>{validation.lname}</p>}
                            </div>
                            <div className='col-sm-6'>
                            <label className='cart-product-version mb-1'>Phone Number</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter Phone Number' name="number" value={inputValues.number} onChange={(e) => handleChange(e)}/>
                                {validation.number && <p className='formErrors'>{validation.number}</p>}
                            </div>
                            <div className='col-sm-6'>
                            <label className='cart-product-version mb-1'>Email Id</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter Email Id' name="email" value={inputValues.email} onChange={(e) => handleChange(e)}/>
                                {validation.email && <p className='formErrors'>{validation.email}</p>}
                            </div>
                            <div className='col-sm-6'>
                            <label className='cart-product-version mb-1'>Address</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter Address' name="address" value={inputValues.address} onChange={(e) => handleChange(e)}/>
                                {validation.address && <p className='formErrors'>{validation.address}</p>}
                            </div>
                            {/* <div className='col-sm-6'>
                            <label className='cart-product-version mb-1'>Country</label>
                            <select className="mb-2 w-100 py-1 px-2 form-control" name="country" value={inputValues.country} onChange={(e) => handleChange(e)}>
                                <option value="">Select Country</option>
                                {appConstant.COUNTRY.length > 0  && appConstant.COUNTRY?.map((result, i) =>
                                  <option value={result} key={i}>{result}</option>
                                )} 
                            </select>
                            {validation.country && <p className='formErrors'>{validation.country}</p>}
                            </div> */}
                            <div className='col-sm-6'>
                            <label className='cart-product-version mb-1'>State</label>
                            <select className="mb-2 w-100 py-1 px-2 form-control" name="state" value={inputValues.state} onChange={(e) => handleChange(e)}>
                                <option value="">Select State</option>                                   
                                {appConstant.STATES.length > 0  && appConstant.STATES?.map((result, i) =>
                                  <option value={result} key={i}>{result}</option>
                                )}                      
                            </select>
                            {validation.state && <p className='formErrors'>{validation.state}</p>}
                            </div>
                            <div className='col-sm-6'>
                            <label className='cart-product-version mb-1'>City</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter City' name="city" value={inputValues.city} onChange={(e) => handleChange(e)}/>
                                {validation.city && <p className='formErrors'>{validation.city}</p>}
                            </div>
                            <div className='col-sm-6'>
                            <label className='cart-product-version mb-1'>Zip/Postal Code</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter Zip/Postal Code' name="zip" value={inputValues.zip} onChange={(e) => handleChange(e)}/>
                                {validation.zip && <p className='formErrors'>{validation.zip}</p>}
                            </div>
                        </div>
                        {isLoadingButton ? 
                        <div className='text-center mt-3'><button className='btn btn-purple' type='submit'>Add Address <img alt="loader" className='ms-2' src='/images/default.gif' width={'16px'} height={'16px'}/></button></div> :
                          <div className='text-center mt-3'><button className='btn btn-purple' type='submit' onClick={(e) => addAddress(e)}>Add Address</button></div>
                        }


                        <div className='row mt-3'>                            
                        {shippingData?.length > 0 && shippingData?.map((result, i) =>
                          <>
                            <div className='col-sm-6 mb-4' key={'shipping'+i}>
                              <div className={result?.default_address === '0' ? 'card add-address' : 'card add-address default'}>
                                <div className='card-body position-relative p-4 pb-2'>
                                {result?.default_address === '0' && <button type="button" className="position-absolute remove-address" onClick={() => {manage_address('delete', result?.id)}}>&times;</button> }
                                    <p className='address'>
                                      <div style={{textTransform : 'capitalize', fontWeight : '700'}}> {result?.first_name} {result?.last_name}</div>
                                      <span style={{textTransform : 'capitalize'}}>{result?.address} {result?.city} {result?.state} {result?.country} {result?.postal_code}</span>
                                    </p>
                                    <div className='d-flex justify-content-between flex-wrap'>
                                      <button type="button" className='btn btn-purple btn-sm mb-2' data-bs-toggle="modal" data-bs-target="#edit-address" onClick={() => {getShipping(result?.id)}}>Edit Address</button>
                                      {result?.default_address === '0' && <button className='btn btn-purple btn-sm mb-2' onClick={() => {manage_address('default', result?.id)}}>Make Default</button> }
                                    </div>
                                </div>
                              </div>
                            </div>
                          </>
                          )}
                        </div>                      
                    </div>                   
                </div>
            </div>
            </div>
        </div>
      </div>
      <Footer />

      {/* edit-address popup  */}
      <div className="modal back-shadow" id="edit-address">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                    <div className="modal-body p-4 ">
                    {isLoading ? <LoadingSpinner/> :
                      <form>
                        <div className='row'>
                          <div className='col-sm-12'>
                            <label className='cart-product-version mb-1'>First Name</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder="First Name" name="fname" value={addressValues.fname} onChange={(e) => handleAddressChange(e)}/>
                            {addressValidation.fname && <p className='formErrors'>{addressValidation.fname}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label className='cart-product-version mb-1'>Last Name</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder="Last Name" name="lname" value={addressValues.lname} onChange={(e) => handleAddressChange(e)}/>
                            {addressValidation.lname && <p className='formErrors'>{addressValidation.lname}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label className='cart-product-version mb-1'>Mobile Number</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder="Mobile Number" name="number" value={addressValues.number} onChange={(e) => handleAddressChange(e)}/>
                            {addressValidation.number && <p className='formErrors'>{addressValidation.number}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label className='cart-product-version mb-1'>Email</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder="Email" name="email" value={addressValues.email} onChange={(e) => handleAddressChange(e)}/>
                            {addressValidation.email && <p className='formErrors'>{addressValidation.email}</p>}
                          </div>
                          <div className='col-sm-12'>
                            <label className='cart-product-version mb-1'>Address</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type='text' name='address' placeholder='Address' value={addressValues.address} onChange={(e) => handleAddressChange(e)}/>
                            {addressValidation.address && <p className='formErrors'>{addressValidation.address}</p>}
                          </div>
                          {/* <div className='col-sm-12'>
                            <label className='cart-product-version mb-1'>Country</label>
                            <select className="mb-2 w-100 py-1 px-2 form-control" name="country"  value={addressValues.country} onChange={(e) => handleAddressChange(e)}>
                                <option value="">Select Country</option>
                                {appConstant.COUNTRY.length > 0  && appConstant.COUNTRY?.map((result, i) =>
                                  <option value={result} key={i}>{result}</option>
                                )} 
                            </select>      
                            {addressValidation.country && <p className='formErrors'>{addressValidation.country}</p>}          
                          </div> */}
                          <div className='col-sm-12'>
                            <label className='cart-product-version mb-1'>State</label>
                            <select className="mb-2 w-100 py-1 px-2 form-control" name="state" value={addressValues.state} onChange={(e) => handleAddressChange(e)}>
                                <option value="">Select State</option> 
                                {appConstant.STATES.length > 0  && appConstant.STATES?.map((result, i) =>
                                  <option value={result} key={i}>{result}</option>
                                )}                              
                            </select>  
                            {addressValidation.state && <p className='formErrors'>{addressValidation.state}</p>}             
                          </div>
                          <div className='col-sm-12'>
                            <label className='cart-product-version mb-1'>City</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter City' name='city' value={addressValues.city} onChange={(e) => handleAddressChange(e)}/>  
                            {addressValidation.city && <p className='formErrors'>{addressValidation.city}</p>}            
                          </div>
                          <div className='col-sm-12'>
                            <label className='cart-product-version mb-1'>Zip/Postal Code</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter Zip/Postal Code' name='zip'  value={addressValues.zip} onChange={(e) => handleAddressChange(e)}/> 
                            {addressValidation.zip && <p className='formErrors'>{addressValidation.zip}</p>}             
                          </div>
                        </div>
                        {isLoadingButton ? <div className='text-center mt-3'><button type='submit' className="btn btn-purple">Update <img alt="loader" className='ms-2' src={`${appConstant.CDN_URL}/assets/images/default.gif`} width={'16px'} height={'16px'}/></button></div> :
                          <div className='text-center mt-3'><button type='button' className="btn btn-purple" onClick={() => {updateShippingAddress()}}>Update</button></div>
                        }            
                      </form>
                    }
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default ShippingAddress;
