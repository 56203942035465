import {useEffect, useState} from "react";
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import ProductCard from "./shared/ProductCard";
import Banner from "./shared/Banner";
import MultiRangeSlider from "multi-range-slider-react";
import Faqs from "./shared/Faqs";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
import Pagination from './shared/Pagination';
import LoadingSpinner from "./shared/LoadingSpinner";

function Shop() {
    const [isLoading, setIsLoading] = useState(true);

    const [minValue, setMinValue] = useState(1);
    const [maxValue, setMaxValue] = useState(10000);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [search, setSearch] = useState('');
    const [mobileFilters, setMobileFilters] = useState(false);
    const [priceFilters, setPriceFilters] = useState(false);
    const [categoryFilters, setCategoryFilters] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [defaultPrice, setDefaultPrice] = useState({
        min: 0,
        max:0
    });
    let [searchParams] = useSearchParams();
    useEffect(() => {
        getCategories();
        if(searchParams?.get('s')){
            getProducts(1, '', '',searchParams?.get('s'),'');
            setSearch(searchParams?.get('s'));
        }else{
            getProducts(1);
        }

        getPrice();
    }, []);
    const params = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
    const getCategories = () => {
        axios.get(`${appConstant.API_URL}/c/category/1`, params)
            .then(res => {
                if(res?.data?.status === 200){
                    setCategories(res?.data?.data);
                }else{
                    setCategories([]);
                }
            })
            .catch(error => {
            });
    }
    const getPrice = () => {
        axios.get(`${appConstant.API_URL}/c/price`, params)
            .then(res => {
                if(res?.data?.status === 200){
                    const startMin = res?.data?.data[0]?.min_price ?? 1;
                    setMinValue(startMin);
                    setMaxValue(res?.data?.data[0]?.max_price);
                    setDefaultPrice({min: startMin, max: res?.data?.data[0]?.max_price});
                }else{
                    setMinValue(1);
                    setMaxValue(10000);
                    setDefaultPrice({min: 1, max: 10000});
                }
            })
            .catch(error => {
            });
    }
    const getProducts = (page = 1, minPrice='', maxPrice = '', search = '', categoryId= '', append=false) => {
        hideMobileFilter()
        if(categoryId){ setSelectedCategoryId(categoryId);}else{setSelectedCategoryId('');}
        const productData = new FormData();
        productData.append('search', search);
        productData.append('category_id', categoryId);
        productData.append('min_price', minPrice);
        productData.append('max_price', maxPrice);
        const params = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        axios.post(`${appConstant.API_URL}/products/${page}`, productData, params)
            .then(res => {
                setIsLoading(false);
                if(res?.data?.status === 200){
                    if(append){
                        setProducts(prevProducts => ({
                            ...prevProducts,
                            current_page: res?.data?.data?.current_page,
                            data: [...prevProducts.data, ...res?.data?.data?.data]
                        }));
                    }else{
                        setProducts(res?.data?.data);
                    }
                }else{
                    setProducts([]);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    }

    const getProductByAmount = (e) =>{
        setMinValue(e.minValue);
        setMaxValue(e.maxValue);
        // getProducts(1, e.minValue, e.maxValue, '', '');
    }

    const handleChange = (e) =>{
        setSearch(e.target.value);
    }
    const searchProducts = () =>{
        getProducts(1, minValue, maxValue, search, '');
    }

    const hideMobileFilter = (e) =>{
        setMobileFilters(false);
    }
    const mobilePriceFilter = (e) =>{
        setMobileFilters(true);
        setCategoryFilters(false);
        setPriceFilters(true);
    }
    const mobileCategoryFilter = (e) =>{
        setMobileFilters(true);
        setPriceFilters(false);
        setCategoryFilters(true);
    }
    const clearFilter = () =>{
        setSearch('');
        setMinValue(defaultPrice.min);
        setMaxValue(defaultPrice.max);
        setSelectedCategoryId('');
        getProducts(1);
    }
    return (
        <>
            <Header id='4'/>
            <Banner name='Shop' parentSlug='/' parent='home'/>
            {isLoading ? <LoadingSpinner/> :
            <section className="product-section pt-5 px-3">
                <h4 className="h1 mb-0 text-center">Controllers & Accessories </h4>
                <h4 className="h5 text-center">Checkout Our Controllers and their Accessories</h4>
                <div className="shell">
                    <div className="row m-0 p-0">
                        <div className="col-lg-3 order-2 order-md-1 d-lg-block d-none">
                            <div className="filters sticky-top">
                                <div className="row">
                                    <div className="col-md-12 order-md-1 order-1">
                                        <h5 className="heading">Filters</h5>
                                        <form action="/shop" className='w-100 d-flex'>
                                            <div className="searchBox">
                                                <input className="search-input form-control" type='search' name='s' value={search} onChange={(e) => handleChange(e)} placeholder="Search..."/>
                                                {/* <button type="button" className="search-btn" onClick={() =>{searchProducts()}}>Go</button> */}
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-12 order-md-2 order-3">                                    
                                        <h5 className="heading">Price</h5>
                                        <div className="price-filter">
                                            <MultiRangeSlider
                                            min={defaultPrice.min}
                                            max={defaultPrice.max}
                                            minValue={minValue}
                                            maxValue={maxValue}
                                            stepOnly={true}
                                            step={500}
                                            onChange={(e) => {
                                                getProductByAmount(e)
                                            }}
                                            style={{ border: "none", boxShadow: "none", padding: "15px 10px" }}
                                            barLeftColor="#fff"
                                            barInnerColor="#f3f3f3"
                                            barRightColor="#fff"
                                            thumbLeftColor="#000"
                                            thumbRightColor="#000"
                                            label={false}
                                            ruler={false}
                                            // labels={['500', '1000', '1500', '2000', '2500', '3500', '4000']}
                                            ></MultiRangeSlider>
                                            <div className="d-flex justify-content-between">
                                                <h6><strong>Min: </strong> {minValue}</h6>
                                                <h6><strong>Max: </strong> {maxValue}</h6>
                                            </div>
                                        </div>
                                        <div className="text-center mb-4">
                                            <button type="button" className="btn-purple btn " onClick={() =>{searchProducts()}}>Apply Filter</button>
                                        </div>
                                    </div>
                                    <div className="col-md-12 order-md-3 order-2">
                                        <h5 className="heading">Categories</h5>
                                        <ul className="list-group mb-3 d-none d-md-block">
                                            {categories?.length > 0  && categories?.map((result, i) =>
                                            <li className={selectedCategoryId === result?.id ? "list-group-item clicked" : "list-group-item"} key={'category' + i}>
                                                <div className="links d-flex justify-content-between align-items-center" onClick={() =>{getProducts(1, minValue, maxValue, '', result?.id)}}>
                                                    <div className="links">
                                                        {result?.category} <span>({result?.totalProducts})</span>
                                                    </div>
                                                    <div>
                                                        {/*<span className="close-btn">&times;</span>                                                        */}
                                                    </div>
                                                </div>
                                            </li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="text-center mb-4 col-md-12 order-md-4 order-4">
                                        <button type="button" className="btn-purple btn " onClick={() =>{clearFilter()}}>Clear Filter</button>
                                    </div>
                                </div>                                                               
                            </div>
                        </div>
                        <div className="col-lg-9 order-1 order-md-2">
                            {searchParams?.get('s') && <h4 className='h4'>Search result for: {searchParams?.get('s')}</h4>}

                            <div className="row m-0 p-0 text-center">
                                {products?.data?.length > 0  && products?.data?.map((result, i) =>
                                <div className="col-xl-4 col-sm-6 col-12" key={'searchProduct' + i}>
                                    <ProductCard data={result}/>
                                </div>
                                )}
                                {products.length  === 0 && (<div className="col-xl-4 col-sm-6 col-12">Not Found.</div>)}
                            </div>
                            {products?.current_page != products?.total_pages && (<div className="text-center mb-4 col-md-12 order-md-4 order-4">
                                <button type="button" className="btn-purple btn" onClick={() =>{getProducts(parseInt(products?.current_page) +1, minValue, maxValue, search, selectedCategoryId, true)}}>Load More</button>
                            </div>)}
                    </div>
                    </div>
                </div> 
                {/* mobile filters*/}
                <div className="mobile-filters d-lg-none d-flex">
                    <div className="filter-name" onClick={() =>{mobilePriceFilter()}}>Price <i className="fa fa-filter"></i></div>
                    <div className="filter-name" onClick={() =>{mobileCategoryFilter()}}>Category <i className="fa fa-filter"></i></div>
                </div>
                {mobileFilters ? 
                    <>                    
                    <div className="filter-content d-lg-none d-block">
                        <div className="mobile-filter-content">
                            <button className="close-filter" onClick={() =>{hideMobileFilter()}}>&times;</button>

                            {/* price filter  */}
                            {priceFilters ? 
                            <>
                            <MultiRangeSlider
                            min={defaultPrice.min}
                            max={defaultPrice.max}
                            minValue={minValue}
                            maxValue={maxValue}
                            stepOnly={true}
                            step={500}
                            onChange={(e) => {
                                getProductByAmount(e)
                            }}
                            style={{ border: "none", boxShadow: "none", padding: "15px 10px" }}
                            barLeftColor="#fff"
                            barInnerColor="#f3f3f3"
                            barRightColor="#fff"
                            thumbLeftColor="#000"
                            thumbRightColor="#000"
                            label={false}
                            ruler={false}
                            // labels={['500', '1000', '1500', '2000', '2500', '3500', '4000']}
                            ></MultiRangeSlider>
                            <div className="d-flex justify-content-between">
                                <h6><strong>Min: </strong> {minValue}</h6>
                                <h6><strong>Max: </strong> {maxValue}</h6>
                            </div>
                            </>
                            : ''}

                            {/* category filter */}
                            {categoryFilters ? 
                            <>
                            <ul className="list-group mb-3">
                                {categories?.length > 0  && categories?.map((result, i) =>
                                <li className="list-group-item clicked" key={'category' + i}>
                                    <div className="d-flex justify-content-between align-items-center" onClick={() =>{getProducts(1, minValue, maxValue, '', result?.id)}}>
                                        <div className={'links'}>
                                            {result?.category} <span>({result?.totalProducts})</span>
                                        </div>
                                        <div>
                                            {/*<span className="close-btn">&times;</span>*/}
                                        </div>
                                    </div>
                                </li>
                                )}
                            </ul>
                            </>
                            : '' }
                            
                            <div className="text-center">
                                <button type="button" className="btn-purple btn btn-sm me-3"  onClick={() =>{searchProducts()}}>Apply</button>
                                <button type="button" className="btn-purple btn btn-sm " onClick={() =>{clearFilter()}}>Clear</button>
                            </div>
                        </div>
                        
                    </div>
                    </>
                : ''}
            </section>
            }
            {/* faqs */}
            <div className="faqsSection py-4">       
                <div className="container py-4" >
                    <div className="row">
                        <div className="col-lg-6">
                            <h4 className="h1 mb-2 text-start">FAQs</h4>
                            <h4 className="h6 text-start mb-4">See below our frequently asked question, for more FAQ, please click this <Link className="links" to='/faqs'>Link</Link></h4>
                            <h4 className="h6 text-start mb-5">Our customer support is available Monday to Friday: 9:00am-5:00pm.<br/>
                            Average answer time: 24h
                            </h4>
                            <div className="text-start mb-4">
                            <Link to='/contact-us' className="btn-login">
                                CONTACT US
                            </Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Faqs page='13'/> 
                        </div>
                    </div>                   
                </div>
            </div> 
            <Footer/>
        </>
    );
  }
  
  export default Shop;
  