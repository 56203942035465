import React, {useEffect, useState} from 'react';
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import Banner from "./shared/Banner";
import { Link } from "react-router-dom";
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
import LoadingSpinner from './shared/LoadingSpinner.js';

function Cart() {
    const [isLoading, setIsLoading] = useState(true);
    const [cartItems, setCartItems] = React.useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [inputValues, setInputValue] = useState({
      quantity: "1"
    });
    const [summaryData, setSummaryData] = useState({
        mrp: 0,
        subTotal: 0,
        discount: 0,
        total: 0
    });
    useEffect(() => {
      cart();
    }, []);

    function handleChange(e, i) {
        let data = [...cartItems];
        data[i].cartData.quantity = e.target.value;
        setCartItems(data);
        summary(data);
        let localData = JSON.parse(localStorage.getItem('cart'));
        localData[i].quantity = e.target.value;
        localStorage.setItem('cart', JSON.stringify(localData));
      }
      const cart = () => {
        const params = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
        const cartData = new FormData();
        cartData.append('cart', localStorage.getItem('cart') ?? '' );
        axios.post(`${appConstant.API_URL}/products/cart`, cartData, params)
            .then(res => {
              setIsLoading(false);
              if(res?.data?.status === 200){
                  if(res?.data?.data?.cart){
                      const cart =  JSON.parse(res?.data?.data?.cart);
                      localStorage.setItem('cart', res?.data?.data?.cart);
                      const cartData = [];
                      cart.map((result, i) => {
                          const item = {
                              cartData: result,
                              product: res?.data?.data?.items[i]
                          }
                          cartData.push(item);
                      });
                      setCartItems(cartData);
                      shouldButtonDisable(cartData);
                      summary(cartData);
                      setTotalPrice(res?.data?.total_price);
                  }else{
                      localStorage.removeItem('cart')
                  }

              }else{
                  setCartItems([]);
              }
            })
            .catch(error => {
            });
      }
      const removeItem = (i, canUndo = true) => {
       //let localData = JSON.parse(localStorage.getItem('cart'));
        let data = [...cartItems];
        if(canUndo && data[i].cartData.frequentlyBuy){
            const isDisplay = !data[i].cartData.display;
            data[i].cartData.display = isDisplay;
           //localData[i].display = isDisplay;
        }else{
            if(data[i].cartData.frequentlyBuy){
                const getParentId = data[i].cartData.parentId;
                if(getParentId === '0'){
                    data.filter((item, j) => (item.cartData.parentId === data[i].cartData.productId)).map((result, k)=>{
                        result.cartData.frequentlyBuy = false;
                        result.cartData.discount = false;
                    });
                }else{
                    data.filter((item, j) => ((item.cartData.parentId === data[i].cartData.parentId) || (item.cartData.productId === data[i].cartData.parentId))).map((result, k)=>{
                        result.cartData.frequentlyBuy = false;
                        result.cartData.discount = false;
                    });
                }
            }
            data = data.filter((item, m) => m !== i);
          //localData = localData.filter((item, m) => m !== i);
        }
        const localData = [];
        data.map((item, i)=>{
            localData.push(item.cartData)
        });
        localStorage.setItem('cart', JSON.stringify(localData));
        shouldButtonDisable(data);
        setCartItems(data);
          summary(data);
      }

      const shouldButtonDisable = (data) => {
        for(let i=0;i<data.length;i++){
            if(data[i].cartData.display){
                setDisableButton(false);
            }else{
                setDisableButton(true);
                break;
            }
        }
      }
    const addition = (val1, val2) => {
        return parseInt(val1) + parseInt(val2);
    }
    const CTA = () => {
        window.location.href = '/checkout/1';
    }
    const summary = (cartItems) => {
        let subTotalCheck = 0;
        let totalDiscount = 0;
        let mrpPrice = 0;
        let grandTotal = 0;
        const items = cartItems.filter((item) => item.cartData.display === true).map((res, i) => {
            if(res.cartData.isVersion && res.cartData.versionName !== res.product.version_name){
                const getVersonPrice = res.product.product_meta.filter((item) => item.version_title === res.cartData.versionName);
                const price = parseInt(getVersonPrice[0].version_price) * parseInt(res.cartData.quantity);
                const salePrice = (parseInt(getVersonPrice[0].version_sale_price) + (res.cartData.typeName === 'remap' ? parseInt(res.product.remap_price) : 0)) * parseInt(res.cartData.quantity);
                let discount = 0;
                if(res.cartData.frequentlyBuy && res.cartData.parentId !== '0'){
                    const percentage = (salePrice / 100) * 10;
                    // discount = percentage;
                    discount = Math.round(percentage);
                }
                mrpPrice = mrpPrice + price;
                subTotalCheck = subTotalCheck + salePrice;
                totalDiscount = totalDiscount + discount;
            }else{
                const price = parseInt(res.product.price) * parseInt(res.cartData.quantity);
                const salePrice = (parseInt(res.product.sale_price) + (res.cartData.typeName === 'remap' ? parseInt(res.product.remap_price) : 0)) * parseInt(res.cartData.quantity);
                let discount = 0;
                if(res.cartData.frequentlyBuy && res.cartData.parentId !== '0'){
                    const percentage = (salePrice / 100) * 10;
                    // discount = percentage;
                    discount = Math.round(percentage);
                }
                mrpPrice = mrpPrice + price;
                subTotalCheck = subTotalCheck + salePrice;
                totalDiscount = totalDiscount + discount;
            }
        });
        grandTotal = subTotalCheck - totalDiscount;
        setSummaryData({
            mrp: mrpPrice,
            subTotal: subTotalCheck,
            discount: totalDiscount,
            total: grandTotal
        })
    }
    const getPrice = (res) => {
        let salePrice = 0;
        if(res.cartData.isVersion && res.cartData.versionName !== res.product.version_name){
            const getVersonPrice = res.product.product_meta.filter((item) => item.version_title === res.cartData.versionName);
            salePrice = (parseInt(getVersonPrice[0].version_sale_price) + (res.cartData.typeName === 'remap' ? parseInt(res.product.remap_price) : 0)) * parseInt(res.cartData.quantity);
        }else{
            salePrice = (parseInt(res.product.sale_price) + (res.cartData.typeName === 'remap' ? parseInt(res.product.remap_price) : 0)) * parseInt(res.cartData.quantity);
        }
        return salePrice;
    }
  return (
    <>
      <Header />
      <Banner name="Cart" parentSlug="/" parent="Home" />
      <div className="faqsSection py-5">
        {isLoading ? <LoadingSpinner/> :
        <>
        {cartItems?.length === 0 && <>
            <div className="container border border-1 p-3">
                <h4 className="h3 mb-3 text-center">Your cart is empty.</h4>
                <div className="text-center">
                    <Link className="btn-login" to="/shop">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Go back to shop
                    </Link>
                </div>
            </div>
        </> }
        {cartItems?.length > 0 && <>
        <div className="container">
            <div className="row">
                <div className="col-lg-8 mb-lg-0 mb-4">
                {cartItems?.length > 0 && cartItems?.map((result, i) =>
                    <div className={"cart-card"} key={i + 'cartItems'}>
                        <Link to={'/product/' + result?.product?.slug}><img className="cart-product-img" src={`${appConstant.IMAGE_URL}/products/` + result?.product?.featured_image} alt='product-name' /></Link>
                        <div className={!result?.cartData?.display ? "flex-grow-1 me-2 disable-div" : "flex-grow-1 me-2"}>
                            <Link to={'/product/' + result?.product?.slug}><h4 className="cart-product-name">{result?.product?.title}</h4></Link>
                            {(result?.cartData?.isVersion && result?.cartData?.versionName) &&<h4 className="cart-product-version">Version : <strong>{result?.cartData?.versionName}</strong></h4>}
                            {/*<h4 className="cart-product-version">Price : <strong>{(result?.cartData?.productType === 'controller' && result?.cartData?.typeName === 'remap') ? (<>&#x20B9; {addition(result?.product?.sale_price, result?.product?.remap_price) * parseInt(result.cartData.quantity)}</>) : (<>&#x20B9; {parseInt(result?.product?.sale_price) * parseInt(result.cartData.quantity)}</>) }</strong></h4>*/}
                            <h4 className="cart-product-version">Price : <strong>{getPrice(result)}</strong></h4>
                            {(result?.cartData?.productType === 'controller' && result?.cartData?.typeName === 'fixed-mapping') && (<ul className="cart-product-list">
                                <li>Upper left : {result?.cartData?.fixedMapping?.upperLeft}</li>
                                <li>Upper right : {result?.cartData?.fixedMapping?.upperRight}</li>
                                <li>Lower left : {result?.cartData?.fixedMapping?.lowerLeft}</li>
                                <li>Lower right : {result?.cartData?.fixedMapping?.lowerRight}</li>
                            </ul>)}
                            {(result?.cartData?.productType === 'controller' && result?.cartData?.typeName === 'remap') && (<i>* Remap price is included.</i>)}
                        </div>
                        <div className="cart-quantity">
                            <input type='number' className="cartNumber  mb-0 mb-sm-3" disabled={!result?.cartData?.display} min='1' max={result?.product?.quantity} name='quantity' value={result?.cartData?.quantity} onChange={(e) => handleChange(e, i, '')}/>
                            <div>
                                {(result?.cartData?.display || !result?.cartData?.frequentlyBuy) ?
                                (<button className="cart-trash d-block" onClick={() => {removeItem(i)}}>Remove <i className="fa fa-trash ms-1" aria-hidden="true"></i></button>) 
                                : 
                                (<>
                                <button className="cart-trash active-div d-block mb-2" onClick={() => {removeItem(i, true)}}>Undo</button> 
                                <button className="cart-trash active-div d-block mb-2" onClick={() => {removeItem(i, false)}}>Permanent Delete</button> 
                                
                                <p className='formErrors mb-0'>You will lost 10% discount</p>
                                
                                </>)}
                            </div>
                        </div>
                    </div>
                )}

                </div>
                <div className="col-lg-4">
                    <div className="card mb-3">
                        <div className="card-header bg-white">
                            <h4 className="cart-product-name">Cart Total</h4>
                        </div>
                        <div className="card-body">                            
                            <h4 className="cart-product-version d-flex justify-content-between w-100 mb-2"><span>MRP</span> <strong>&#x20B9; {summaryData.mrp} </strong></h4>
                            <h4 className="cart-product-version d-flex justify-content-between w-100 mb-2"><span>Subtotal</span> <strong>&#x20B9; {summaryData.subTotal} </strong></h4>
                            <h4 className="cart-product-version d-flex justify-content-between w-100 mb-2"><span>Discount</span> <strong>&#x20B9; {summaryData.discount}</strong></h4>
                            <h4 className="cart-product-version d-flex justify-content-between w-100 mb-2"><span>Total</span> <strong>&#x20B9; {summaryData.total} </strong></h4>
                        </div>
                        <div className="card-footer text-center bg-white p-2">
                            <button className="cart-trash filled" onClick={() => CTA()} disabled={disableButton}>Proceed to Checkout <i className="fa fa-arrow-right ms-1"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        }
        </>
        }
      </div>
      <Footer />
    </>
  );
}

export default Cart;
