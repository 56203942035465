import {useState, useEffect} from 'react';
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import Banner from "./shared/Banner";
import Sidebar from "./shared/Sidebar";
import axios from 'axios';
import appConstant from './shared/constant/constant.json';
import LoadingSpinner from "./shared/LoadingSpinner";
function MyProfile() {
  useEffect(() => {
    getUserDetails();
  }, []);
  // const [userDetail, setUserDetail] = useState({});
  const [resultMessage, setResultMessage] = useState('');
  const [isLoadingPwd, setIsLoadingPwd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [inputValues, setInputValue] = useState({
      fname: "",
      lname: "",
      number: "",
      email: "",
  });
  const [validation, setValidation] = useState({
      fname: "",
      lname: "",
      number: "",
      email: "",
  });
  function handleChange(e) {
      const { name, value } = e.target;
      setInputValue({ ...inputValues, [name]: value });
  }
  const checkValidation = () => {
      let errors = JSON.parse(JSON.stringify(validation));
  
      //first validation
      const alphabetCond = /^[a-zA-Z ]*$/;
      if (!inputValues.fname) {
      errors.fname = "First name is required";
      } else if (!inputValues.fname.match(alphabetCond)) {
      errors.fname = "Please enter valid first name";
      } else {
      errors.fname = "";
      }
      //last validation
      if (!inputValues.lname) {
      errors.lname = "Last name is required";
      } else if (!inputValues.lname.match(alphabetCond)) {
      errors.lname = "Please enter valid last name";
      } else {
      errors.lname = "";
      }
      //number validation
      // const numberCond = /^[0-9\b]+$/;
      if (!inputValues.number) {
      errors.number = "Number is required";
      }
      // else if (!inputValues.number.match(numberCond)) {
      // errors.number = "Please enter valid number";
      // }
      else {
      errors.number = "";
      }
      // email validation
      const emailCond = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!inputValues.email) {
        errors.email = "Email is required";
      } else if (!inputValues.email.match(emailCond)) {
        errors.email = "Please enter valid email address";
      } else {
        errors.email = "";
      }
      setValidation(errors);
      if(errors.fname === '' && errors.lname === '' && errors.number === '' && errors.email === ''){
        return true;
      }else{
        return false;
      }
  };
  const getUserDetails = () => {
    setIsLoading(true);
    const userDetailsData = new FormData();
    userDetailsData.append('token', localStorage.getItem('token') );
    const params = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
    };
    axios.post(`${appConstant.API_URL}/user`, userDetailsData, params)
          .then(res => {
              setIsLoading(false);
              if (res?.data?.status === 200) {
                // setUserDetail(res?.data?.data);
                  const newData = {
                  fname: res?.data?.data?.first_name,
                  lname: res?.data?.data?.last_name,
                  number: res?.data?.data?.phone_number,
                  email: res?.data?.data?.email,
                  }
                  setInputValue(newData);
              }else {

              }
          })
          .catch(error => {
              setIsLoading(false);
              // props.changeLogin(true);
          });
  }
  const editProfile = (e) => {
      e.preventDefault();
      const isSuccess = checkValidation();
      if(isSuccess) {          
        setIsLoadingButton(true);
          const userDetailsData = new FormData();
      userDetailsData.append('token', localStorage.getItem('token') );
      userDetailsData.append('first_name', inputValues.fname );
      userDetailsData.append('last_name', inputValues.lname );
      userDetailsData.append('phone_number', inputValues.number );
      const params = {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*"
      };
      axios.post(`${appConstant.API_URL}/user/update`, userDetailsData, params)
            .then(res => {
                setIsLoadingButton(false);
                if (res?.data?.status === 200) {
                  window.location = window.location.href;
                }else {

                }
            })
            .catch(error => {
                setIsLoadingButton(false);
                // props.changeLogin(true);
            });
      }
  }

  const [pwdInputValues, setPwdInputValues] = useState({
    oldPassword : "",
    newPassword : "",
    confirmPassword : ""
});
const handlePwdChange = (e) => {
    const {name, value} = e.target;
    setPwdInputValues({...pwdInputValues, [name]: value});
}
const [pwdValidation, setPwdValidation] = useState({
    oldPassword : "",
    newPassword : "",
    confirmPassword : ""
});
const checkpwdValidation = () => {
    let errors = JSON.parse(JSON.stringify(pwdValidation));

    //old password validation
    const oldPassword = pwdInputValues.oldPassword;
    // const passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6})/;
    if (!oldPassword) {
      errors.oldPassword = "Old Password is required";
    }
    // else if (!oldPassword.match(passReg)) {
    //   errors.oldPassword = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
    // }
    else {
      errors.oldPassword = "";
    }

    //new password validation
    const newPassword = pwdInputValues.newPassword;
    if (!newPassword) {
      errors.newPassword = "New Password is required";
    }
    // else if (!newPassword.match(passReg)) {
    //   errors.newPassword = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
    // }
    else {
      errors.newPassword = "";
    }
    //confirm password validation
    const confirmPassword = pwdInputValues.confirmPassword;
    if (!confirmPassword) {
    errors.confirmPassword = 'Repeat New Password';
    } else if (confirmPassword !== newPassword) {
    errors.confirmPassword = 'Both password should match';
    } else {
    errors.confirmPassword = '';
    }

    setPwdValidation(errors);
    if(errors.oldPassword === '' && errors.newPassword === '' && errors.confirmPassword === ''){
      return true;
    }else{
      return false;
    }
};
const changePassword = (e) => {
    e.preventDefault();
    const isSuccess = checkpwdValidation();
    if(isSuccess) {
      setIsLoadingPwd(true);
      const changePwd = new FormData();
        changePwd.append('token', localStorage.getItem('token') );
        changePwd.append('old_pwd', pwdInputValues.oldPassword );
        changePwd.append('new_pwd', pwdInputValues.newPassword );
        const params = {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
        };
        axios.post(`${appConstant.API_URL}/user/update_password`, changePwd, params)
            .then(res => {
                setIsLoadingPwd(false);
                if (res.data.status === 200) {

                    setResultMessage(res?.data?.message);
                }else {
                    setResultMessage(res?.data?.message);
                }
            })
            .catch(error => {
                setIsLoadingPwd(false);
        });
    }        
}
  return (
    <>
      <Header />
      <Banner name="My Profile" parentSlug="/" parent="Home" />
      <div className="faqsSection py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-md-0 mb-0">
                <Sidebar id='1'/>
            </div>
            <div className="col-md-8 mb-md-0 mb-4">
              {isLoading ? <LoadingSpinner/> :
                <div className='card'>
                    <div className='card-header'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <h4 className="h5 mb-0">Personal Information</h4>
                      </div>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-sm-6'>
                            <label className='cart-product-version mb-1'>First Name</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter First Name' name="fname" value={inputValues.fname} onChange={(e) => handleChange(e)}/>
                            {validation.fname && <p className='formErrors'>{validation.fname}</p>}
                            </div>
                            <div className='col-sm-6'>
                            <label className='cart-product-version mb-1'>Last Name</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter Last Name' name="lname" value={inputValues.lname} onChange={(e) => handleChange(e)}/>
                            {validation.lname && <p className='formErrors'>{validation.lname}</p>}
                            </div>
                            <div className='col-sm-6'>
                            <label className='cart-product-version mb-1'>Phone Number</label>
                            <input className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter Phone Number' name="number" value={inputValues.number} onChange={(e) => handleChange(e)}/>
                                {validation.number && <p className='formErrors'>{validation.number}</p>}
                            </div>
                            <div className='col-sm-6'>
                            <label className='cart-product-version mb-1'>Email Id</label>
                            <input disabled className="mb-2 w-100 py-1 px-2 form-control" type="text" placeholder='Enter Email Id' name="email" value={inputValues.email} onChange={(e) => handleChange(e)}/>
                                {validation.email && <p className='formErrors'>{validation.email}</p>}
                            </div>                            
                        </div>

                        {isLoadingButton ? 
                        <div className='text-center mt-3'>
                          <button className='btn btn-purple' onClick={editProfile}>Update <img className='ms-2' alt='' src='/images/default.gif' width={'16px'} height={'16px'}/></button>
                        </div> :
                        <div className='text-center mt-3'><button className='btn btn-purple' onClick={editProfile}>Update</button></div>
                        }                             
                    </div>
                    <div className='card-header'>
                      <h4 className="h5 mb-0">Change Password</h4>
                    </div>
                    <div className='card-body'>
                      {resultMessage && <p className='formErrors mb-2'>{resultMessage}</p>}
                      <div className='row'>
                        <div className='col-sm-4'>
                          <label className='cart-product-version mb-1'>Old Password</label>
                          <input className="mb-2 w-100 py-1 px-2 form-control" type="password" name='oldPassword' placeholder='Old Password' value={pwdInputValues.oldPassword} onChange={(e) => handlePwdChange(e)}/>
                          {pwdValidation.oldPassword && <p className='formErrors'>{pwdValidation.oldPassword}</p>}
                        </div>
                        <div className='col-sm-4'>
                          <label className='cart-product-version mb-1'>New Password</label>
                          <input className="mb-2 w-100 py-1 px-2 form-control" type="text" name='newPassword' placeholder='New Password' value={pwdInputValues.newPassword} onChange={(e) => handlePwdChange(e)}/>
                          {pwdValidation.newPassword && <p className='formErrors'>{pwdValidation.newPassword}</p>}
                        </div>
                        <div className='col-sm-4'>
                          <label className='cart-product-version mb-1'>Confirm Password</label>
                          <input className="mb-2 w-100 py-1 px-2 form-control" type="text" name='confirmPassword' placeholder='Confirm Password' value={pwdInputValues.confirmPassword} onChange={(e) => handlePwdChange(e)}/>
                          {pwdValidation.confirmPassword && <p className='formErrors'>{pwdValidation.confirmPassword}</p>}
                        </div>
                      </div> 
                      {isLoadingPwd ? <div className='text-center mt-3'><button className="btn btn-purple">Change Password <img className='ms-2' src={`${appConstant.CDN_URL}/assets/images/default.svg`} alt='loader' width={'16px'} height={'16px'}/></button></div> :
                        <div className='text-center mt-3'><button className="btn btn-purple" onClick={changePassword}>Change Password</button></div>
                      } 
                    </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MyProfile;
