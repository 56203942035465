import Header from "./shared/Header";
import Footer from "./shared/Footer";
import Banner from "./shared/Banner";

function TermsServices() {
    return (
        <>
            <Header/>
            <Banner name='Terms & Services' parentSlug='/' parent='Home'/>
            
            <div className="container py-5">
                <h4 className="h6 mb-4">Welcome to Sage Controllers! These terms and conditions ("Terms") govern your use of the website sagecontrollers.com ("Website") and your purchase of customised gaming controllers and accessories ("Products") from our online store. By accessing or using our Website and purchasing Products, you agree to be bound by these Terms. Please read them carefully.</h4>

                <h4 className="h3 fw-bold mb-2">Account Creation</h4>
                <ul>
                    <li><h4 className="h6 mb-4"><strong>Account Registration:</strong> You have the option to create an account on our Website by providing accurate and complete information. It is your responsibility to maintain the confidentiality of your account credentials and to restrict access to your account. You are responsible for all activities that occur under your account.</h4></li>
                    <li><h4 className="h6 mb-4"><strong>Guest Checkout:</strong> If you prefer not to create an account, you can proceed with guest checkout to make a purchase. However, note that certain features and benefits may only be available to registered users.</h4></li>
                </ul>

                <h4 className="h3 fw-bold mb-2">Product Orders and Payments</h4>
                <ul>
                    <li><h4 className="h6 mb-4"><strong>Order Placement:</strong> By placing an order on our Website, you make an offer to purchase the selected Products under these Terms. We reserve the right to accept or reject your order at our discretion. If your order is accepted, we will send you an order confirmation email.</h4></li>
                    <li><h4 className="h6 mb-4"><strong>Product Customization: </strong> If you choose to customize your gaming controller or accessories, please ensure that the details, including color, design, and any personalized elements, are accurate before submitting your order. Once the customization process begins, changes may not be possible.</h4></li>
                    <li><h4 className="h6 mb-4"><strong>Pricing and Payment: </strong> The prices of the Products are displayed on our Website and are subject to change without notice. Payments are processed through secure third-party payment processors. You agree to provide accurate and valid payment information and authorize us or the payment processor to charge the specified amount for your order.</h4></li>
                </ul>

                <h4 className="h3 fw-bold mb-2">Shipping and Delivery</h4>
                <ul>
                    <li><h4 className="h6 mb-4"><strong>Shipping:</strong> We will make reasonable efforts to ship your ordered Products within the estimated time frames mentioned on our Website. However, please note that unforeseen circumstances may cause delays, and delivery times are approximate.</h4></li>
                    <li><h4 className="h6 mb-4"><strong>Shipping Address:</strong> It is your responsibility to provide an accurate and complete shipping address during the checkout process. We are not responsible for delays or additional charges incurred due to incorrect or incomplete addresses.</h4></li>
                    <li><h4 className="h6 mb-4"><strong>Title and Risk of Loss: </strong> Title and risk of loss pass to you upon delivery of the Products to the shipping carrier. We are not liable for any loss, damage, or theft of the Products after they are delivered to the carrier.</h4></li>
                </ul>

                <h4 className="h3 fw-bold mb-2">Returns and Refunds</h4>
                <ul>
                    <li><h4 className="h6 mb-4"><strong>Return Eligibility:</strong> We accept returns and provide refunds or exchanges for defective or damaged Products. Please review our Returns and Refunds Policy available on our Website for detailed instructions and eligibility criteria.</h4></li>
                    <li><h4 className="h6 mb-4"><strong>Return Process:</strong> To initiate a return or request a refund, please contact our customer support team within the specified timeframe and provide the necessary information and supporting documentation.</h4></li>
                </ul>

                <h4 className="h3 fw-bold mb-2">Intellectual Property</h4>
                <ul>
                    <li><h4 className="h6 mb-4"><strong>Ownership:</strong> The Website, including its content, designs, logos, trademarks, and other intellectual property, is owned or licensed by Sage Controllers or have been used with consent of third parties who are holders of copyrights. You agree not to use, reproduce, modify, or distribute any intellectual property without our prior written consent.</h4></li>
                </ul>

                <h4 className="h3 fw-bold mb-2">Limitation of Liability</h4>
                <ul>
                    <li><h4 className="h6 mb-4"><strong>Disclaimer:</strong> The Products and information provided on our Website are offered on an "as is" and "as available" basis. We make no warranties or representations, express or implied, regarding the accuracy, reliability, or completeness of the content or the fitness of the Products for a particular purpose.</h4></li>
                    <li><h4 className="h6 mb-4"><strong>Indirect Damages:</strong> In no event shall Sage Controllers be liable for any indirect, incidental, special, or consequential damages arising from the use of our Products or Website, including but not limited to loss of profits, data, or business opportunities.</h4></li>
                </ul>
                <h4 className="h3 fw-bold mb-2">Governing Law and Jurisdiction</h4>
                <ul>
                    <li><h4 className="h6 mb-4"><strong>Governing Law:</strong> These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of laws principles.</h4></li>
                    <li><h4 className="h6 mb-4"><strong>Jurisdiction:</strong> Any disputes arising out of or related to these Terms shall be subject to the exclusive jurisdiction of the courts located in new delhi, India.</h4></li>
                </ul>

                <h4 className="h3 fw-bold mb-2">Severability</h4>
                <h4 className="h6 mb-4">If any provision of these Terms is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue to be valid and enforceable to the fullest extent permitted by law.</h4>

                <h4 className="h3 fw-bold mb-2">Changes to Terms</h4>
                <h4 className="h6 mb-4">We reserve the right to update or modify these Terms at any time without prior notice. The updated version will be indicated by the "Effective Date" at the beginning of these Terms. Your continued use of the Website and purchase of Products after any modifications constitutes your acceptance of the revised Terms.</h4>

                <h4 className="h3 fw-bold mb-2">Contact Us</h4>
                <h4 className="h6 mb-4">If you have any questions, concerns, or feedback regarding these Terms or any aspect of our Website or Products, please contact our customer support team. We will respond to your inquiries as soon as reasonably possible.</h4>

                <h4 className="h6 mb-4">Thank you for reading our Terms and Conditions. Enjoy shopping at Sage Controllers!</h4>
            </div>
            
            <Footer/>
        </>
    );
  }
  
  export default TermsServices;
  