import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './Home';
import ContactUs from './ContactUs';
import AboutUs from './AboutUs';
import ProductCategory from './ProductCategory';
import Shop from './Shop';
import Blogs from './Blogs';
import SingleProduct from './SingleProduct';
import FaqsPage from './FaqsPage';
import PrivacyPolicy from './PrivacyPolicy';
import RefundPolicy from './RefundPolicy';
import TermsServices from './TermsServices';
import ShippingAddress from './ShippingAddress';
import Thanks from './Thanks';
import Failed from './Failed';
import Cart from './Cart';
import Checkout from './Checkout';
import MyProfile from './MyProfile';
import Orders from './Orders';
import Logout from './shared/Logout';
import BlogDetail from './BlogDetail';
import Services from './Services';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter,Routes, Route } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Routes>
        {/* backend  */}
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/reset-password/:email/:otp" element={<Home />}></Route>
        <Route exact path="/contact-us" element={<ContactUs />}></Route>
        <Route exact path="/about-us" element={<AboutUs />}></Route>
        <Route exact path="/faqs" element={<FaqsPage />}></Route>
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route exact path="/refund-policy" element={<RefundPolicy />}></Route>
        <Route exact path="/terms-and-services" element={<TermsServices />}></Route>
        <Route exact path="/shop" element={<Shop />}></Route>
        <Route exact path="/blogs" element={<Blogs />}></Route>
        <Route exact path="/blogs/:slug" element={<BlogDetail />}></Route>
        <Route exact path="/cart" element={<Cart />}></Route>
        <Route exact path="/checkout/:type" element={<Checkout />}></Route>
        <Route exact path="/category/:slug" element={<ProductCategory />}></Route>
        <Route exact path="/product/:slug" element={<SingleProduct />}></Route>
        <Route exact path="/shipping-address" element={<ShippingAddress />}></Route>
        <Route exact path="/success" element={<Thanks />}></Route>
        <Route exact path="/failed" element={<Failed />}></Route>
        <Route exact path="/my-profile" element={<MyProfile />}></Route>
        <Route exact path="/my-orders/:type" element={<Orders />}></Route>
        <Route exact path="/services/:type" element={<Services />}></Route>
        <Route exact path="/logout" element={<Logout />}></Route>
        <Route path="*" element={<Home />}></Route>
      </Routes>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
