import { Link } from "react-router-dom";
import appConstant from "./constant/constant.json";
import moment from "moment";

function BlogCards(props) {
    const data = props.data;
    return (
        <>
            <Link to={'/blogs/' + data?.blog_slug}>
                <div className='blog-card'>
                    <img className="blog-img" src={`${appConstant.IMAGE_URL}/blogs/` + data?.blog_image} alt={data?.title}/>
                    <div className="blog-body">
                        <h6 className='blog-title'>{data?.blog_title}</h6>
                        <p className='blog-content'>Published on {moment(data?.date_time).format('MMMM D, YYYY')}</p>
                    </div>
                </div>
            </Link>
        </>
    );
  }
  
  export default BlogCards;