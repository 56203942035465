import React, {useEffect, useState} from 'react';
import Header from "./shared/Header";
import Footer from "./shared/Footer";
import Banner from "./shared/Banner";
import Sidebar from "./shared/Sidebar";
import { useParams} from "react-router-dom";
import axios from "axios";
import appConstant from "./shared/constant/constant.json";
import DataTable from 'react-data-table-component';
import LoadingSpinner from "./shared/LoadingSpinner";

function Orders() {
  const props = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    getOrders();
  }, []);

  const ExpandedComponent = ({ data }) => <small><table className="table table-bordered">
    <thead>
    <tr>
      <td className='first-child' style={{width: '16%'}}>Image</td>
      <td style={{width: '59%'}}>ITEM(S)</td>
      <td style={{width: '5%'}}>QTY</td>
      <td style={{width: '10%'}}>MRP</td>
      <td style={{width: '10%'}}>SUB TOTAL</td>
    </tr>
    </thead>
    <tbody>
    {data?.meta?.length > 0 && data?.meta?.map((result, m) =>
        <>
          <tr key={m + 'cartItems'}>
            <td><img className='product-image me-2' width={'100px'} height={'100px'} src={data.type === '0' ? appConstant.IMAGE_URL + '/products/' + result.product_image : result.product_image}  alt="product"/></td>
            <td dangerouslySetInnerHTML={{__html: result?.product_title}}></td>
            <td>{result?.quantity}</td>
            <td>₹ {result?.price}</td>
            <td>₹ {result?.sub_total}</td>
          </tr>
        </>
    )}
    </tbody>
  </table></small>;
  const columns = [
    {
      name: 'Order ID',
      selector: row => row.order_id,
    },
    {
      name: 'Order Date',
      selector: row => row.date_time,
    },
    {
      name: 'Price',
      selector: row => '₹ ' + row.total_price,
    },
    {
      name: 'Order Status',
      selector: row => row.order_status,
    },
    {
      name: 'Payment Method',
      selector: row => row.payment_type,
    },
    {
      name: 'Invoice',
      selector: row => row.invoice ? <a href={row.invoice} style={{color:'blue',textDecoration:'underline'}} target={'_blank'}>Invoice</a> : 'N/A',
    },
  ];
  const getOrders = () => {
    setIsLoading(true);
    const userDetailsData = new FormData();
    userDetailsData.append('token', localStorage.getItem('token') );
    const params = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    };
    let urlOrder;
    if(props?.type === 'services'){
      urlOrder = '1';
    }else{
      urlOrder = '';
    }
    axios.post(`${appConstant.API_URL}/products/user_orders/${urlOrder}`, userDetailsData, params)
        .then(res => {
          setIsLoading(false);
          if (res?.data?.status === 200) {
            setOrders(res?.data?.data);
            for(let i=0; i<res?.data?.data?.length; i++){
              const Title = {
                name: 'Order ID',
                selector: row => row.order_id
              }
            }

          }else {
            setOrders([]);
          }
        })
        .catch(error => {
          setIsLoading(false);
        });
  }
  return (
    <>
      <Header />
      <Banner name="My Orders" parentSlug="/" parent="Home" />
      <div className="faqsSection py-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 mb-md-0 mb-0">
                <Sidebar id='2'/>
            </div>
            <div className="col-md-8 mb-md-0 mb-4">
              {isLoading ? <LoadingSpinner/> :
                <div className='card'>
                    <div className='card-header'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <h4 className="h5 mb-0">My Orders</h4>
                      </div>
                    </div>
                    <div className='card-body'>
                    <DataTable
                       pagination
                        dense
                        columns={columns}
                        data={orders}
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                    />                                                                                      
                    </div>                   
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Orders;
